<template>
  <div class="register-container">
    <h4 class="delete-candidate-heading">Delete a Candidate</h4>
    <ul>
      <li style="color:white" v-for="candidate in localCandidates" :key="candidate.id" class="candidate">
        {{ candidate.name }}
        <button class="delete-btn" @click="deleteCandidate(candidate.id)">Delete</button>
        <button class="edit-btn" @click="startEditing(candidate)">Edit</button>

        <div v-if="editingCandidateId === candidate.id" class="edit-form-container">
          <form @submit.prevent="submitForm" class="edit-form">
            <label for="name">Name:</label>
            <input type="text" id="name" v-model="editingCandidate.name" class="input-field">
            <label for="studyDirection">Study Direction:</label>
            <input type="text" id="studyDirection" v-model="editingCandidate.studyDirection" class="input-field">
            <label for="university">University:</label>
            <input type="text" id="university" v-model="editingCandidate.university" class="input-field">
            <label for="faculty">Faculty:</label>
            <input type="text" id="faculty" v-model="editingCandidate.faculty" class="input-field">
            <label for="enrollmentNumber">Enrollment Number:</label>
            <input type="text" id="enrollmentNumber" v-model="editingCandidate.enrollmentNumber" class="input-field">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="editingCandidate.email" class="input-field">

            <button type="submit" class="submit-btn">Update Candidate</button>
          </form>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    candidates: {
      type: Array,
      default: () => [],
    },

    userId: {
      type: Number ,
      required: true,
    },
  },
  data() {
    return {
      editingCandidateId: null,
      editingCandidate: {
        name: '',
        studyDirection: '',
        university: '',
        faculty: '',
        enrollmentNumber: '',
        email: '',
      },
      localCandidates: [],
    };
  },
  watch: {
    candidates: {
      immediate: true,
      handler(newCandidates) {
        this.localCandidates = [...newCandidates];
      },
    },
  },
  methods: {
    startEditing(candidate) {
      this.editingCandidateId = candidate.id;
      this.editingCandidate = { ...candidate };
    },
    async submitForm() {
      try {
        await axios.put(`https://diplomainsight.onrender.com/candidate/${this.editingCandidateId}`, this.editingCandidate);
        const index = this.localCandidates.findIndex(candidate => candidate.id === this.editingCandidateId);
        this.localCandidates[index] = this.editingCandidate;
        this.editingCandidateId = null;
        this.editingCandidate = {
          name: '',
          studyDirection: '',
          university: '',
          faculty: '',
          enrollmentNumber: '',
          email: '',
        };
      } catch (error) {
        console.error(error);
      }
    },
    async deleteCandidate(id) {
      try {
        await axios.delete(`https://diplomainsight.onrender.com/candidate/${id}`);
        this.localCandidates = this.localCandidates.filter(candidate => candidate.id !== id);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style src="../css/deleteCandidate.css" scoped></style>
